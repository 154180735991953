import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  appender(event) {
    event.preventDefault()
    const element = event.target

    $(document).unbind("click.appender")
    $(document).bind("click.appender", element, () => {
      this.doAppender(element)
      $(document).unbind("click.appender")
    })
  }

  doAppender(element) {
    const targetName = $(element).data("target")
    const target = this.targets.find(targetName)
    const template = $(element).data("template")
    const html = template.replaceAll("TS", new Date().getTime())

    $(target).append(html)
  }

  remover(event) {
    event.preventDefault()
    const element = event.target

    $(document).unbind("click.reomver")
    $(document).bind("click.reomver", element, () => {
      this.doRemover(element)
      $(document).unbind("click.reomver")
    })
  }

  doRemover(element) {
    const containerName = $(element).data("container")
    const container = $(element).closest(containerName)
    $(container).find(":input").prop("disabled", true)
    $(container).find("[data-remover]").prop("disabled", false)
    $(container).hide()
  }
}
