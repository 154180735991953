import _ from 'lodash'
import $ from 'jquery'

function findContainer(element, selector) {
  if (_.startsWith(selector, '#')) {
    return $(selector)
  }

  return $(element).closest(selector)
}


function handleResponse($element, data) {
  if (!$element.data('xhrml')) { return }
  $element.removeData('xhrml')
  $element.data('type', 'xhrml')

  const target = $element.data('target')
  const $target = findContainer($element, target)

  $target.html(data)
  $target.trigger('xhrml:success')
  $target.trigger('content:loading')
  $target.trigger('content:loaded')
}

function handleRequest($element) {
  if ($element.data('type') !== 'xhrml') { return }

  if (!$element.data('raw-params')) {
    const params = $element.data('params') || ''
    $element.data('raw-params', params)
  }

  const fieldsContainer = $element.data('fields-container')

  if (fieldsContainer) {
    const $fieldsContainer = findContainer($element, fieldsContainer)
    const fields = []
    $fieldsContainer.find(':input').each(function() {
      const name = $(this).data('param-name') || this.name
      if (name) {
        const value = $(this).val()
        fields.push(`${encodeURIComponent(name)}=${encodeURIComponent(value)}`)
      }
    })

    const rawParams = $element.data('raw-params')
    const params = rawParams.split('&').concat(fields).join('&')
    $element.data('params', params)
  }

  $element.data('type', 'html')
  $element.data('xhrml', true)
}

$(function() {
  $(document).on({
    'ajax:before'(event) {
      const $element = $(event.target)
      handleRequest($element)
    },
    'ajax:success'(event, data) {
      const $element = $(event.target)
      handleResponse($element, data)
    },
    'ajax:error'(event, xhr) {
      const $element = $(event.target)
      handleResponse($element, xhr.responseText)
    }
  })
})


$(document).on('ajax:aborted:file', function(event) {
  const $form = $(event.target)
  $(event.target).ajaxSubmit({
    beforeSerialize($f) {
      $f.trigger('ajax:before')
    },
    success(data) {
      $form.trigger('ajax:success', data)
    },
    error(xhr) {
      $form.trigger('ajax:error', xhr)
    }
  })

  return false
})
