import * as echarts from 'echarts'
import $ from 'jquery'
import _ from 'lodash'


const charts = []

window.chart = function(domId, option) {
  const element = document.getElementById(domId)
  const chart = echarts.init(element)
  charts.push(chart)
  chart.setOption(option)
  return chart
}

$(function() {
  $(window).on("resize", function() {
    _.each(charts, function(chart) {
      chart.resize()
    })
  })
})
