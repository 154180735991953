import '@fortawesome/fontawesome-free/css/all.css'

$(function() {
  $(document).on('ajax:send', ".icon-link", function(event) {
    $(this).find(".fa-sync").addClass("fa-spin")
  })
  $(document).on('ajax:complete', ".icon-link", function(event) {
    $(this).find(".fa-sync").removeClass("fa-spin")
  })
})
