// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// 
// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

import _ from 'lodash'
import 'jquery-ujs'

import '../bootstrap-ext'

import '../components/fontawesome'
import '../components/echarts'
import '../components/numbro'
import '../components/moment'
import '../components/turbolinks'
import '../components/xhrml'
import '../components/stimulus'

window.$ = $
window._ = _
window.jQuery = $

import '../frontend'
