import "./style.scss"
import "./pages.scss"


function numberToHumanSize(bytes) {
  if (bytes == null) {
  return "-";
  }

  var precision = 2;
  var kilobyte = 1024;
  var megabyte = kilobyte * 1024;
  var gigabyte = megabyte * 1024;
  var terabyte = gigabyte * 1024;

  if ((bytes >= kilobyte) && (bytes < megabyte)) {
  return (bytes / kilobyte).toFixed(precision) + ' KB';

  } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
  return (bytes / megabyte).toFixed(precision) + ' MB';

  } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
  return (bytes / gigabyte).toFixed(precision) + ' GB';

  } else if (bytes >= terabyte) {
  return (bytes / terabyte).toFixed(precision) + ' TB';

  } else {
  return bytes + ' B';
  }
}

window.numberToHumanSize = numberToHumanSize
