import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  disconnect() {
    clearTimeout(this.timeoutId)
    clearInterval(this.intervalId)
  }

  connect() {
    $(() => {
      const interval = $(this.element).data("interval")
      if (interval) {
        this.intervalId = setInterval(this.click.bind(this), interval)
      }

      const delay = $(this.element).data("delay")
      if (delay != undefined) {
        this.timeoutId = setTimeout(this.click.bind(this), delay)
      }
    })
  }

  click() {
    $(this.element).click()
  }
}
