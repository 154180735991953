import { Controller } from "stimulus"
import { v4 as uuidv4 } from 'uuid'

export default class extends Controller {
  static targets = [ "input" ]

  take() {
    this.inputTarget.value = uuidv4()
  }
}
